<template>
  <PpModal v-model="open">
    <PpCard class="box has-border is-flex">
      <div class="edit-profile-modal-content modal-columns-outer">
        <div
          v-if="step===1"
          class="columns is-multiline">
          <div class="column is-12">
            <h3 class="is-size-4 has-text-secondary has-text-weight-bold">
              <i class="el-icon-warning has-text-danger" /> Delete client?
            </h3>
          </div>
          <div class="column is-12">
            <p>
              Are you use you want to delete <strong>{{ companyName }}</strong> ?<br>
              Once deleted, you cannot recover this client and its data. The users beneath this client will not be able to access the system.
            </p>
          </div>
          <div class="column is-12 has-text-right">
            <el-button
              class="button-secondary-outline"
              style="min-width: 125px;"
              @click="handleClose">
              Cancel
            </el-button>
            <el-button
              class="button-secondary"
              style="min-width: 125px;"
              @click="step=2">
              Yes, Delete
            </el-button>
          </div>
        </div>
        <el-form
          v-if="step===2"
          ref="confirmForm"
          :model="confirmForm"
          :rules="rules"
          label-position="top"
          status-icon
          class="columns is-multiline"
          @submit.native.prevent>
          <div class="column is-12">
            <h3
              class="is-size-4 has-text-secondary has-text-weight-bold">
              <i class="el-icon-warning has-text-danger" /> Confirm deletion
            </h3>
          </div>
          <el-form-item
            label="Please enter your password to delete"
            class="column is-12"
            prop="password">
            <el-input
              v-model.trim="confirmForm.password"
              placeholder="password"
              type="password"
              autocomplete="password" />
          </el-form-item>
          <div
            v-if="error"
            class="column is-12">
            <el-alert
              :title="error"
              :closable="false"
              type="error" />
          </div>
          <div class="column is-12 has-text-right">
            <el-button
              :disabled="isFetching"
              class="button-secondary-outline"
              style="min-width: 115px;"
              @click="handleClose">
              Cancel
            </el-button>
            <el-button
              :loading="isFetching"
              class="button-secondary"
              style="min-width: 125px;"
              native-type="button"
              @click="handleDelete">
              Delete
            </el-button>
          </div>
        </el-form>
        <div
          v-if="step===3"
          class="columns is-multiline">
          <div class="column is-12">
            <h3 class="is-size-4 has-text-secondary has-text-weight-bold">
              Client is deleted
            </h3>
          </div>
          <div class="column is-12">
            <p>Client <strong>{{ companyName }}</strong> has been removed from eBEDS.</p>
          </div>
          <div class="column is-12 has-text-right">
            <el-button
              class="button-secondary-outline"
              style="min-width: 125px;"
              @click="handleDeleteSuccess">
              Ok
            </el-button>
          </div>
        </div>
      </div>
    </PpCard>
  </PpModal>
</template>

<script>
export default {
  name: 'DeleteClient',
  props: {
    open: {
      type: Boolean,
      default: false
    },
    appCode: {
      type: String,
      default: ''
    },
    companyName: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      confirmForm: {
        password: ''
      },
      step: 1,
      rules: {
        password: [
          {
            min: 6,
            message: this.$t('views.customer.form.password.invalid'),
            trigger: ['change', 'blur']
          },
          {
            required: true,
            message: this.$t('views.customer.form.password.required'),
            trigger: ['change', 'blur']
          }
        ]
      },
      isFetching: false,
      error: ''
    }
  },
  methods: {
    handleDelete () {
      this.error = ''
      this.$refs.confirmForm.validate(async (valid) => {
        if (valid) {
          this.isFetching = true
          this.$store.dispatch(
            'admin/updateClient',
            { appCode: this.appCode, password: this.confirmForm.password, unlink: true }
          ).then(() => {
            this.step = 3
            this.isFetching = false
          }).catch((err) => {
            this.error = err.message || (err.error && err.error.message) || ''
            this.isFetching = false
          })
        }
      })
    },
    handleClose () {
      this.error = ''
      this.isFetching = false
      this.step = 1
      this.confirmForm.password = ''
      this.$emit('update:open', false)
    },
    handleDeleteSuccess () {
      this.$router.push({ name: 'admin/client-management' })
    }
  }
}
</script>

<style scoped>

</style>
