export default {
  data () {
    return {
      isFetchingClient: false,
      config: {},
      pricing: {},
      company: {},
      companyName: '',
      appCode: '',
      isSubmitting: false,
      isFetching: false,
      logo: undefined
    }
  },
  created () {
    this.fetchClient()
  },
  methods: {
    fetchClient () {
      this.isFetchingClient = true
      this.$store.dispatch('admin/clientOne', this.$route.params.appCode)
        .then(({ outlets }) => {
          const { config, pricing, ...rest } = outlets.details
          this.config = config
          this.pricing = pricing
          this.company = rest
          this.companyName = config.companyName
          this.appCode = rest.appCode
          this.isFetchingClient = false
          if (config.hasOwnProperty('images') &&
            config.images.hasOwnProperty('mail')) {
            this.logo = { ...this.logo, ...config.images.mail }
          }
        })
    }
  }
}
