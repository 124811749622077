<template>
  <div>
    <ClientManagementSubMenu :company="company">
      {{ companyName }}
    </ClientManagementSubMenu>
    <div
      v-if="!isFetchingClient"
      class="section">
      <div class="container">
        <div class="columns">
          <div class="column">
            <h2 class="has-text-weight-bold is-size-4 has-text-secondary">
              {{ $t('views.admin.Pricing') }}
            </h2>
          </div>
        </div>
        <el-form
          ref="localPricingForm"
          :model="localPricingForm"
          class="columns is-large-gap is-multiline"
          label-position="left"
          status-icon>
          <div class="column is-6 m-b-2x">
            <div class="columns">
              <div class="column">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('views.admin.forexMarkup.label') }}
                </div>
                <div>{{ $t('views.admin.forexMarkup.desc') }}</div>
              </div>
              <el-form-item
                class="column is-narrow"
                prop="forexMarkupPct">
                <el-input-number
                  v-model="localPricingForm.forexMarkupPct"
                  :min="0"
                  :controls="false"
                  class="percentage-input-number" />
              </el-form-item>
            </div>
          </div>
          <div class="column is-6 m-b-2x">
            <div class="columns">
              <div class="column">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('views.admin.transactionFee.label') }}
                </div>
                <div>{{ $t('views.admin.transactionFee.desc') }}</div>
              </div>
              <el-form-item
                class="column is-narrow"
                prop="txFeesDefaultPct">
                <el-input-number
                  v-model.trim="localPricingForm.adjustmentPct"
                  :min="0"
                  :max="100"
                  :controls="false"
                  class="percentage-input-number" />
              </el-form-item>
            </div>
          </div>
          <div class="column is-6 m-b-2x">
            <div class="columns">
              <div class="column">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('views.admin.minMarkup.label') }}
                </div>
                <div>{{ $t('views.admin.minMarkup.desc') }}</div>
              </div>
              <el-form-item
                class="column is-narrow"
                prop="minMarkupInPct">
                <el-input-number
                  v-model.trim="localPricingForm.minMarkupInPct"
                  :max="100"
                  :controls="false"
                  class="percentage-input-number" />
              </el-form-item>
            </div>
          </div>
          <!-- FEE for MORE than 30USD -->
          <div class="column is-6 m-b-2x">
            <div class="columns">
              <div class="column">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('views.admin.lowValueTransactionFee.label') }}
                </div>
                <div>{{ $t('views.admin.lowValueTransactionFee.desc') }}</div>
              </div>
              <el-form-item
                class="column is-narrow"
                prop="minCCFeeBelowUSD">
                <el-input
                  v-model.trim="localPricingForm.additionalFee"
                  v-number-input.float="{decimal:2}"
                  :min="0"
                  :max="100"
                  :placeholder="0.0"
                  style="width:180px">
                  <template slot="suffix">
                    USD
                  </template>
                </el-input>
              </el-form-item>
            </div>
          </div>
          <div class="column is-6 m-b-2x">
            <div class="columns">
              <el-form-item
                class="column is-narrow"
                prop="hideAdjustments">
                <el-checkbox v-model="localPricingForm.hideAdjustments">
                  Hide transaction fee at checkout
                </el-checkbox>
              </el-form-item>
            </div>
          </div>
          <div class="column is-12 has-text-left">
            <hr class="m-t-0 m-b-5x">
            <div class="columns is-large-gap">
              <div class="column is-narrow">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('views.admin.Published ver') }}
                </div>
                <div>{{ pricing.id }}</div>
              </div>
              <div class="column is-narrow">
                <div class="has-text-primary has-text-weight-bold ">
                  {{ $t('views.admin.Published at') }}
                </div>
                <div>{{ pricing.createdAt|date('DD MMM YYYY, HH:MM:SS') }}</div>
              </div>
              <div class="column is-narrow">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('views.admin.Published by') }}
                </div>
                <div>{{ pricing.editor.displayName }}</div>
              </div>
              <div
                class="column is-narrow"
                style="margin-left: auto">
                <el-button
                  :loading="isSubmitting"
                  class="button-secondary"
                  @click="handleSave">
                  {{ $t('views.admin.Publish') }}
                </el-button>
              </div>
            </div>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { numberRound } from '@/plugins/number'
import ClientManagementSubMenu from '@/views/admin/ClientManagementSubMenu'
import selectedClient from './mixins/selectedClient'

export default {
  name: 'ClientManagementPricing',
  components: {
    ClientManagementSubMenu
  },
  mixins: [selectedClient],
  data () {
    return {
      localPricingForm: {
        txFeesDefaultPct: undefined,
        minMarkupInPct: undefined,
        forexMarkupPct: undefined,
        hideAdjustments: false,
        additionalFee: undefined
      }
    }
  },
  watch: {
    pricing: {
      deep: false,
      immediate: true,
      handler: function (nV) {
        if (nV && nV.txFees) {
          this.localPricingForm.adjustmentPct = numberRound(nV.finalAdjustmentAmountConfig.YES.FIXED_ADJUSTMENT.COST.list[0].adjustmentPct * 100)
          this.localPricingForm.minMarkupInPct = nV.minMarkupInPct ? numberRound(nV.minMarkupInPct * 100) : 0
          this.localPricingForm.forexMarkupPct = nV.forexMarkupPct ? numberRound(nV.forexMarkupPct * 100) : 0
          this.localPricingForm.hideAdjustments = nV.finalAdjustmentAmountConfig.showAdjustments !== 'YES'
          this.localPricingForm.additionalFee = nV.additionalFee.value ? numberRound(nV.additionalFee.value / 100) : 0
        }
      }
    }
  },
  methods: {
    handleSave () {
      const { id, createdAt, updatedAt, ...rest } = this.pricing
      const payload = {
        ...rest,
        finalAdjustmentAmountConfig: {
          ...this.pricing.finalAdjustmentAmountConfig,
          showAdjustments: this.localPricingForm.hideAdjustments ? 'NO' : 'YES',
          YES: {
            type: 'FIXED_ADJUSTMENT',
            FIXED_ADJUSTMENT: {
              ...this.pricing.finalAdjustmentAmountConfig.YES.FIXED_ADJUSTMENT,
              COST: {
                list: [{
                  type: 'Transaction Fee',
                  adjustmentPct: this.localPricingForm.adjustmentPct / 100
                }]
              }
            }
          }
        },
        minMarkupInPct: this.localPricingForm.minMarkupInPct / 100,
        forexMarkupPct: this.localPricingForm.forexMarkupPct / 100,
        additionalFee: { ...this.pricing.additionalFee, value: this.localPricingForm.additionalFee * 100 }
      }
      this.isSubmitting = true
      this.$store.dispatch('admin/updatePricing', payload).then(
        (resp) => {
          this.fetchClient()
          this.isSubmitting = false
          this.$message({
            message: 'Pricing has been updated',
            type: 'success'
          })
        }
      ).catch((err) => {
        this.isSubmitting = false
        this.$message({
          message: err.message || (err.error && err.error.message) || err.error || '',
          type: 'error'
        })
      })
    }
  },
  metaInfo () {
    const title = this.companyName ? `${this.companyName}'s Pricing` : ''
    return {
      title: title
    }
  }

}
</script>

<style scoped>

</style>
