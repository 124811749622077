<template>
  <div>
    <ClientManagementSubMenu :company="company">
      {{ companyName }}
    </ClientManagementSubMenu>
    <div class="section">
      <div class="container">
        <div class="columns">
          <div class="column">
            <h2 class="has-text-weight-bold is-size-4 has-text-secondary">
              Wallet ({{ walletCurrency }})
            </h2>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <div class="is-size-5 m-b-2x">
              <b class="has-text-primary">{{ $t('views.MyAccount.Current balance') }}:</b> {{ currentBalance | currency({ currency: walletCurrency }) }}
            </div>
            <div class="is-size-5">
              <b class="has-text-primary">Allow negative balance:</b> {{ minBalance | currency({ currency: walletCurrency }) }}
              <el-link
                icon="el-icon-edit"
                class="has-text-secondary has-text-weight-bold m-l-2x"
                @click="negativeBalanceModalActive=true">
                EDIT
              </el-link>
            </div>
            <div
              v-if="!isFetching"
              style="margin-top: 15px;">
              <el-checkbox
                v-model="emailMeDaily">
                {{ $t('views.MyAccount.emailMeDaily') }}
              </el-checkbox>
            </div>
          </div>
          <div class="column is-narrow">
            <el-button
              type="text"
              class="has-text-danger m-r-2x"
              @click="handleAdjustBalance">
              Adjust Balance
            </el-button>
            <el-button
              class="button-secondary"
              style="min-width: 120px;"
              @click="topUpModalActive=true">
              Top Up
            </el-button>
          </div>
        </div>
        <hr style="margin: 0 0 25px 0;">
        <div class="columns">
          <div class="column">
            <DateRangFilterForm @searched="handleFilterApply" />
          </div>
          <div class="column is-narrow">
            <el-button
              type="primary"
              style="min-width: 140px;"
              @click="handleExport">
              {{ $t('views.MyAccount.Export to Excel') }}
            </el-button>
          </div>
        </div>
        <el-table
          ref="singleTable"
          v-loading="isFetching"
          :data="tableData"
          stripe
          style="width: 100%">
          <el-table-column
            :label="$t('views.MyAccount.Timestamp')"
            width="170">
            <template slot-scope="scope">
              {{ scope.row.createdAt | toSGT }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('views.MyBookings.User')">
            <template slot-scope="scope">
              {{ scope.row.displayName }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('views.MyAccount.Action')">
            <template slot-scope="scope">
              {{ $t(`views.MyAccount.action.${scope.row.action}`) }}
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('views.MyAccount.Amount')"
            align="right">
            <template slot-scope="scope">
              <span :class="`${scope.row.type === 'DB' ?'has-text-danger':'has-text-success'}`">
                {{ formatCcy(scope.row) | number }}
              </span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('views.MyAccount.Balance')">
            <template slot-scope="scope">
              {{ (scope.row.endingBalance / 100) | number }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('views.MyAccount.Currency')">
            <template slot-scope="scope">
              {{ scope.row.currency }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('views.MyAccount.Remarks')">
            <template slot-scope="scope">
              <div
                style="width: 150px"
                class="has-text-ellipsis">
                {{ scope.row.metadata.remark }}
              </div>
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          v-if="!isFetching"
          :total-items="totalItems"
          :page="query.page"
          :page-size="10"
          :pager-count="5"
          @change="handlePageChange" />

        <!-- MODALS GOES HERE -->
        <TopUpModal
          :open.sync="topUpModalActive"
          :company-name="companyName"
          :currency="walletCurrency"
          @confirm="handleTopUpConfirm" />
        <NegativeBalanceModal
          :open.sync="negativeBalanceModalActive"
          :currency="walletCurrency"
          @confirm="handleNegativeBalanceConfirm" />
        <PpModal v-model="adjustModalActive">
          <PpCard class="box has-border is-flex">
            <div class="edit-profile-modal-content modal-columns-outer">
              <el-form
                ref="adjustForm"
                :model="adjustForm"
                :rules="adjustFormRules"
                class="columns is-multiline"
                label-position="top">
                <div class="column is-12">
                  <h3 class="is-size-4 has-text-secondary has-text-weight-bold">
                    Adjust balance
                  </h3>
                </div>
                <div class="column is-12">
                  <p><b class="has-text-primary">Current balance:</b> {{ currentBalance | currency({currency:walletCurrency}) }}</p>
                </div>
                <el-form-item
                  class="column is-narrow"
                  prop="action">
                  <el-select
                    v-model.trim="adjustForm.action"
                    style="width: 125px;">
                    <el-option
                      v-for="o in adjustActionOptions"
                      :key="o.value"
                      :value="o.value">
                      {{ o.label }}
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  class="column is-narrow"
                  prop="amount">
                  <div class="el-input-number-wrapper">
                    <el-input-number
                      v-model.number="adjustForm.amount"
                      :min="0"
                      :controls="false"
                      placeholder="Amount" />
                    <span class="hack-input-number-suffix">{{ walletCurrency }}</span>
                  </div>
                </el-form-item>
                <el-form-item
                  class="column is-12"
                  prop="remark">
                  <el-input
                    v-model="adjustForm.remark"
                    :rows="3"
                    type="textarea"
                    placeholder="Remarks" />
                </el-form-item>

                <div class="column is-12 has-text-right">
                  <el-button
                    class="button-secondary-outline"
                    style="min-width: 115px;"
                    @click="handleAdjustCancel">
                    {{ $t('views.customer.form.cancel') }}
                  </el-button>
                  <el-button
                    class="button-secondary"
                    style="min-width: 115px;"
                    @click="handleAdjustSave">
                    Adjust
                  </el-button>
                </div>
              </el-form>
            </div>
          </PpCard>
        </PpModal>
        <PpModal v-model="adjustConfirmModalActive">
          <PpCard class="box has-border is-flex">
            <div class="edit-profile-modal-content modal-columns-outer">
              <el-form
                ref="adjustConfirmForm"
                :model="adjustConfirmForm"
                :rules="adjustConfirmFormRules"
                class="columns is-multiline"
                label-position="top">
                <div class="column is-12">
                  <h3 class="is-size-4 has-text-secondary has-text-weight-bold">
                    Confirm adjust balance
                  </h3>
                </div>
                <div class="column is-12">
                  <p class="m-b">
                    You are about to adjust the balance of {{ companyName }}. Are you sure you want to proceed?
                  </p>
                  <p class="m-b">
                    <b class="has-text-primary">{{ actionLabel }} amount: </b> <span class="m-r-4x">{{ adjustForm.amount|currency({currency:walletCurrency}) }}</span> <b class="has-text-primary">New Balance: </b> <span class="m-r-4x">{{ newBalance|currency({currency:walletCurrency}) }}</span>
                  </p>
                  <p><b class="has-text-primary">Remarks:</b><br> {{ adjustForm.remark }}</p>
                </div>
                <el-form-item
                  class="column is-12"
                  prop="password"
                  label="Please enter your password to proceed">
                  <el-input
                    v-model.trim="adjustConfirmForm.password"
                    type="password"
                    placeholder="password" />
                </el-form-item>

                <div class="column is-12 has-text-right">
                  <el-button
                    class="button-secondary-outline"
                    style="min-width: 115px;"
                    @click="handleAdjustConfirmCancel">
                    {{ $t('views.customer.form.cancel') }}
                  </el-button>
                  <el-button
                    :loading="isPosting"
                    class="button-secondary"
                    style="min-width: 170px;"
                    @click="handleAdjustConfirm">
                    Proceed
                  </el-button>
                </div>
              </el-form>
            </div>
          </PpCard>
        </PpModal>
      </div>
    </div>
  </div>
</template>

<script>

import ClientManagementSubMenu from '@/views/admin/ClientManagementSubMenu'
import DateRangFilterForm from '@/views/customer/DateRangFilterForm'
import selectedClient from './mixins/selectedClient'
import Pagination from '@/components/Pagination'
import { download } from '@/plugins/util'
import TopUpModal from '@/views/admin/components/TopUpModal'
import NegativeBalanceModal from '@/views/admin/components/NegativeBalanceModal'
import dates from '@/plugins/dates'

export default {
  name: 'ClientManagementWallet',
  components: {
    NegativeBalanceModal,
    TopUpModal,
    DateRangFilterForm,
    ClientManagementSubMenu,
    Pagination
  },
  filters: {
    toSGT: (val) => {
      return dates.toSGT(val)
    }
  },
  mixins: [selectedClient],
  beforeRouteUpdate (to, from, next) {
    this.query.currency = to.params.currency
    this.fetchData(this.query)
    next()
  },
  data () {
    return {
      query: {
        page: 1,
        action: undefined,
        date: undefined,
        currency: undefined,
        type: 'wallet'
      },
      isPosting: false,
      negativeBalanceModalActive: false,
      companyName: undefined,
      balance: undefined,
      topUpModalActive: false,
      confirmModalActive: false,
      adjustModalActive: false,
      adjustConfirmModalActive: false,
      adjustForm: {
        amount: undefined,
        remark: undefined,
        action: 'Minus'
      },
      adjustActionOptions: [
        {
          value: 'Minus',
          label: 'Minus'
        }, {
          value: 'Plus',
          label: 'Plus'
        }
      ],
      adjustFormRules: {
        amount: [
          {
            required: true,
            message: 'Amount is required.',
            trigger: [ 'blur' ]
          }
        ]
      },
      adjustConfirmFormRules: {
        password: [
          {
            min: 6,
            message: this.$t('views.customer.form.password.invalid'),
            trigger: [
              'change', 'blur'
            ]
          }, {
            required: true,
            message: this.$t('views.customer.form.password.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ]
      },
      adjustConfirmForm: {
        password: undefined
      },
      tableData: [],
      totalItems: 0
    }
  },
  computed: {
    emailMeDaily: {
      get () {
        return this.config.email ? this.config.email.dailyBalanceReminder : false
      },
      set (v) {
        const payload = {
          appCode: this.$route.params.appCode,
          dailyBalanceReminder: v
        }
        this.config.email.dailyBalanceReminder = v
        this.$store.dispatch('admin/updateDailyBalanceReminder', payload).then(({ outlets }) => {
          this.$message({
            message: `Daily balance reminder has been ${v ? 'activated' : 'disabled'}`,
            type: 'success'
          })
        }).catch((err) => {
          this.config.email.dailyBalanceReminder = !this.config.email.dailyBalanceReminder
          this.$message({
            message: err.message || (err.error && err.error.message) || '',
            type: 'error'
          })
        })
      }
    },
    newBalance () {
      if (this.adjustForm.action && this.adjustForm.amount) {
        if (this.adjustForm.action === 'Minus') {
          return this.currentBalance - this.adjustForm.amount
        } else {
          return this.currentBalance + this.adjustForm.amount
        }
      } else {
        return this.balance
      }
    },
    actionLabel () {
      return this.adjustForm.action
    },
    minBalance () {
      return this.config.payment && this.config.payment.wallet[this.walletCurrency] ? this.config.payment.wallet[this.walletCurrency].minBalance / 100 : 0
    },
    currentBalance () {
      return this.company.balance && this.company.balance[this.walletCurrency] ? (this.company.balance[this.walletCurrency].value / 100) || 0 : 0
    },
    walletCurrency () {
      return this.$route.params.currency
    }
  },
  created () {
    this.query.currency = this.$route.params.currency
    this.fetchData(this.query)
  },
  methods: {
    formatCcy (item) {
      if (!item) return 'N/A'

      let amount = item.amount
      if (item.orderReference) {
        amount = 0 - amount
      }
      return `${item.type === 'DB' ? '-' : '+'}${parseFloat(Number(amount) / 100).toFixed(2)}`
    },
    handlePageChange ({ page }) {
      this.query.page = page
      this.fetchData(this.query)
    },
    handleFilterApply (val) {
      this.query.action = val.action || undefined
      if (val.dateRange.checkInDate && val.dateRange.checkOutDate) {
        const serializedDate = dates.fullDayRange([val.dateRange.checkInDate, val.dateRange.checkOutDate]).map(e => e.toJSON()).join('|')
        this.query.date = serializedDate !== '|' ? serializedDate : undefined
      }
      this.fetchData(this.query)
    },
    fetchData (query) {
      const params = {
        ...query,
        appCode: this.$route.params.appCode
      }
      this.isFetching = true
      this.$store.dispatch('admin/walletTransactions', params).then(
        ({ outlets }) => {
          this.tableData = outlets.items
          this.totalItems = outlets.totalItems
          this.isFetching = false
        }
      )
    },
    handleTopUpConfirm (data) {
      this.$store.dispatch(
        'admin/confirmTopup',
        { ...data, appCode: this.$route.params.appCode, currency: this.walletCurrency }
      ).then(({ outlets }) => {
        if (outlets.ok) {
          this.fetchClient()
          this.fetchData(this.query)
          this.$message({
            message: 'Balance has been updated.',
            type: 'success'
          })
        }
      }).catch((err) => {
        this.$message.error(err.message || (err.error && err.error.message) || '')
      })
    },
    handleAdjustBalance () {
      this.$confirm(`You are about to adjust balance of ${this.companyName}. To top up, please use the top up button. <br/>Are you sure you want to proceed?`, 'Adjust balance', {
        confirmButtonText: 'Proceed',
        cancelButtonText: 'Cancel',
        cancelButtonClass: 'button-secondary-outline',
        confirmButtonClass: 'button-secondary',

        dangerouslyUseHTMLString: true
      }).then(() => {
        this.adjustModalActive = true
      }).catch(() => {
        // todo
      })
    },
    handleAdjustCancel () {
      this.$refs.adjustForm.resetFields()
      this.adjustModalActive = false
    },
    handleAdjustSave () {
      this.$refs.adjustForm.validate((valid) => {
        if (valid) {
          // todo
          this.adjustModalActive = false
          this.adjustConfirmModalActive = true
          return true
        } else {
          // todo
          return false
        }
      })
    },
    handleAdjustConfirm () {
      this.$refs.adjustConfirmForm.validate((valid) => {
        if (valid) {
          this.isPosting = true
          const payload = {
            ...this.adjustForm,
            ...this.adjustConfirmForm,
            appCode: this.$route.params.appCode,
            currency: this.walletCurrency
          }
          this.$store.dispatch('admin/adjustBalance', payload).then(
            ({ outlets }) => {
              if (outlets.ok) {
                this.isPosting = false
                this.fetchClient()
                this.fetchData(this.query)
                this.handleAdjustConfirmCancel()
                this.adjustConfirmForm.password = undefined
              }
            }
          ).catch((err) => {
            this.isPosting = false
            this.adjustConfirmModalActive = false
            this.$store.commit('SET_GLOBAL_ERROR_MODAL', {
              show: true,
              content: err.message || (err.error && err.error.message) || ''
            })
          })
        } else {
          // todo
          return false
        }
      })
    },
    handleAdjustConfirmCancel () {
      this.adjustForm.amount = undefined
      this.adjustForm.remark = undefined
      this.adjustForm.action = 'Minus'
      this.$refs.adjustConfirmForm.resetFields()
      this.adjustConfirmModalActive = false
    },
    handleExport () {
      const params = {
        ...this.query,
        appCode: this.$route.params.appCode
      }
      this.$store.dispatch('admin/exportWalletTransactions', params).then(({ data, headers }) => {
        download(data, headers)
      }).catch((err) => {
        this.$store.commit('SET_GLOBAL_ERROR_MODAL', {
          show: true,
          content: err.message || (err.error && err.error.message) || ''
        })
      })
    },
    handleNegativeBalanceConfirm (data) {
      const payload = {
        appCode: this.$route.params.appCode,
        minBalance: (0 - data.amount) * 100,
        maxBalance: null,
        currency: this.walletCurrency
      }
      this.$store.dispatch('admin/updateNegativeBalance', payload).then(
        () => {
          this.fetchClient()
        }
      ).catch(err => {
        this.$store.commit('SET_GLOBAL_ERROR_MODAL', {
          show: true,
          content: err.message || (err.error && err.error.message) || ''
        })
      })
    }
  },
  metaInfo () {
    const title = this.companyName ? `${this.companyName}'s Wallet` : ''
    return {
      title: title
    }
  }

}
</script>

<style scoped>

</style>
