<template>
  <PpModal v-model="open">
    <PpCard class="box has-border is-flex">
      <div class="edit-profile-modal-content modal-columns-outer">
        <div
          v-if="step===1 && !isAdmin"
          class="columns is-multiline">
          <div class="column is-12">
            <h3 class="is-size-4 has-text-secondary has-text-weight-bold">
              <i class="el-icon-warning has-text-danger" /> {{ $t('views.admin.unlink-user.t1') }}
            </h3>
          </div>
          <div class="column is-12">
            <p v-html="$t('views.admin.unlink-user.t2',{email:user.email, companyName:user.companyName})" />
          </div>
          <div class="column is-12 has-text-right">
            <el-button
              class="button-secondary-outline"
              style="min-width: 125px;"
              @click="handleClose">
              Cancel
            </el-button>
            <el-button
              class="button-secondary"
              style="min-width: 125px;"
              @click="step=2">
              {{ $t('views.admin.Yes-Unlink') }}
            </el-button>
          </div>
        </div>
        <div
          v-if="step===1 && isAdmin"
          class="columns is-multiline">
          <div class="column is-12">
            <h3 class="is-size-4 has-text-secondary has-text-weight-bold">
              <i class="el-icon-warning has-text-danger" /> {{ $t('views.admin.unlink-user.t3') }}
            </h3>
          </div>
          <div class="column is-12">
            <p v-html="$t('views.admin.unlink-user.t4',{email:user.email})" />
          </div>
          <div class="column is-12 has-text-right">
            <el-button
              class="button-secondary-outline"
              style="min-width: 125px;"
              @click="handleClose">
              Ok
            </el-button>
          </div>
        </div>
        <el-form
          v-if="step===2"
          ref="confirmForm"
          :model="confirmForm"
          :rules="rules"
          label-position="top"
          status-icon
          class="columns is-multiline"
          @submit.native.prevent>
          <div class="column is-12">
            <h3
              class="is-size-4 has-text-secondary has-text-weight-bold">
              <i class="el-icon-warning has-text-danger" /> Confirm removal
            </h3>
          </div>
          <el-form-item
            label="Please enter your password to remove"
            class="column is-12"
            prop="password">
            <el-input
              v-model.trim="confirmForm.password"
              placeholder="password"
              type="password"
              autocomplete="password" />
          </el-form-item>
          <div
            v-if="error"
            class="column is-12">
            <el-alert
              :title="error"
              :closable="false"
              type="error" />
          </div>
          <div class="column is-12 has-text-right">
            <el-button
              :disabled="isFetching"
              class="button-secondary-outline"
              style="min-width: 115px;"
              @click="handleClose">
              Cancel
            </el-button>
            <el-button
              :loading="isFetching"
              class="button-secondary"
              style="min-width: 125px;"
              native-type="button"
              @click="handleRemove">
              Remove
            </el-button>
          </div>
        </el-form>
        <div
          v-if="step===3"
          class="columns is-multiline">
          <div class="column is-12">
            <h3 class="is-size-4 has-text-secondary has-text-weight-bold">
              {{ $t('views.admin.unlink-user.t5') }}
            </h3>
          </div>
          <div class="column is-12">
            <p v-html="$t('views.admin.unlink-user.t6',{email:user.email})" />
          </div>
          <div class="column is-12 has-text-right">
            <el-button
              class="button-secondary-outline"
              style="min-width: 125px;"
              @click="handleRemoveSuccess">
              Ok
            </el-button>
          </div>
        </div>
      </div>
    </PpCard>
  </PpModal>
</template>

<script>
// selectedClient can only be used on the clients page. This component needs to work on the user pages at the same time.
// import selectedClient from '@/views/admin/mixins/selectedClient'

export default {
  name: 'RemoveUser',
  // mixins: [selectedClient],
  props: {
    open: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object,
      default () {
        return {}
      }
    },
    unlinked: {
      type: Boolean,
      default: false
    },
    appCode: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      confirmForm: {
        password: ''
      },
      step: 1,
      rules: {
        password: [
          {
            min: 6,
            message: this.$t('views.customer.form.password.invalid'),
            trigger: ['change', 'blur']
          },
          {
            required: true,
            message: this.$t('views.customer.form.password.required'),
            trigger: ['change', 'blur']
          }
        ]
      },
      isFetching: false,
      error: ''
    }
  },
  computed: {
    isAdmin () {
      return this.user.roleCode === 'CLIENT_ADMIN' || this.user.roleCode === 'SUPER_ADMIN'
    }
  },
  methods: {
    handleRemove () {
      this.error = ''
      this.$refs.confirmForm.validate(async (valid) => {
        if (valid) {
          this.isFetching = true
          if (this.unlinked) {
            const payload = {
              appCode: this.appCode,
              ...(this.user),
              delete: true
            }
            this.$store.dispatch('admin/clientUser', payload).then(
              ({ outlets }) => {
                if (outlets.ok) {
                  this.step = 3
                  this.isFetching = false
                }
              }
            ).catch((err) => {
              this.error = err.message || (err.error && err.error.message) || ''
              this.isFetching = false
            })
          } else {
            const payload = {
              uid: this.user.uid,
              delete: true,
              password: this.confirmForm.password
            }
            this.$store.dispatch(
              'admin/updateUser',
              payload
            ).then(() => {
              this.step = 3
              this.isFetching = false
            }).catch((err) => {
              this.error = err.message || (err.error && err.error.message) || ''
              this.isFetching = false
            })
          }
        }
      })
    },
    handleClose () {
      this.error = ''
      this.isFetching = false
      this.step = 1
      this.confirmForm.password = ''
      this.$emit('update:open', false)
    },
    handleRemoveSuccess () {
      this.$router.push({ name: 'admin/user-management' })
    }
  }
}
</script>

<style scoped>

</style>
