<template>
  <div>
    <ClientManagementSubMenu :company="company">
      {{ companyName }}
    </ClientManagementSubMenu>
    <div class="section">
      <div class="container">
        <div class="columns">
          <div class="column">
            <h2 class="has-text-weight-bold is-size-4 has-text-secondary">
              {{ $t('views.MyAccount.Users') }}
            </h2>
          </div>
          <div class="column is-narrow">
            <el-button
              class="button-secondary"
              style="min-width: 140px;"
              @click="addUserModalActive=true">
              {{ $t('views.admin.Add User') }}
            </el-button>
          </div>
        </div>
        <el-table
          ref="usersTable"
          v-loading="isFetching"
          :data="users"
          stripe
          style="width: 100%"
          @row-click="handleSelected">
          <el-table-column
            type="index"
            width="50" />
          <el-table-column :label="$t('views.MyBookings.User')">
            <template slot-scope="scope">
              {{ scope.row.displayName }}
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('views.customer.form.email.label')"
            property="email" />
          <el-table-column :label="$t('views.MyAccount.Role')">
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.roleCode"
                @change="handleRoleChange($event, scope.row)">
                <el-option
                  v-for="ro in roleOptions"
                  :key="ro.value"
                  :value="ro.value"
                  :label="ro.label" />
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('views.MyAccount.Status')"
            width="100">
            <template slot-scope="scope">
              <el-switch
                :value="!scope.row.apps[0].inactive"
                @click.native.stop
                @change="handleStatusChange($event, scope.row)" />
            </template>
          </el-table-column>
          <el-table-column width="50">
            <template slot-scope="scope">
              <el-link
                icon="el-icon-close"
                style="font-size: 1.25rem;"
                type="danger"
                @click.stop="removeUser(scope.row, companyName)" />
            </template>
          </el-table-column>
        </el-table>

        <Pagination
          top-element="#app"
          :total-items="usersCount"
          :page="pagination.page"
          :page-size="pagination.limit"
          @change="fetchData" />
      </div>
    </div>
    <PpModal v-model="addUserModalActive">
      <PpCard class="box has-border is-flex">
        <div class="edit-profile-modal-content modal-columns-outer">
          <el-form
            ref="addUserForm"
            :model="addUserForm"
            class="columns is-multiline"
            label-position="top"
            status-icon>
            <div class="column is-12">
              <h3 class="is-size-4 has-text-secondary has-text-weight-bold">
                {{ $t('views.admin.Add User') }}
              </h3>
            </div>
            <div class="column is-12">
              {{ $t('views.admin.add-user-desc') }}
            </div>
            <el-form-item
              :label="$t('views.admin.username-email.label')"
              class="column is-12 m-b-5x"
              prop="usernameEmail">
              <el-autocomplete
                v-model.trim="addUserForm.usernameEmail"
                :fetch-suggestions="handleFetchSuggestions"
                :trigger-on-focus="false"
                :debounce="600"
                :loading="isFetchingSuggestion"
                select-when-unmatched
                style="width: 100%;"
                value-key="email"
                @select="handleUserSuggestSelect">
                <template slot-scope="{ item }">
                  <div
                    v-if="item.type === 'create'"
                    class="list is-narrow">
                    <div class="list-content">
                      <div class="list-label">
                        No users found.
                      </div>
                      <div class="list-value">
                        {{ item.email || '–' }}
                      </div>
                    </div>
                  </div>
                  <div
                    v-else
                    class="list is-narrow">
                    <div class="list-content">
                      <div class="list-value">
                        {{ item.displayName || '–' }}
                      </div>
                      <div class="list-label">
                        {{ item.email || '–' }}
                      </div>
                    </div>
                  </div>
                </template>
              </el-autocomplete>
            </el-form-item>

            <div class="column is-12 has-text-right">
              <el-button
                class="button-secondary-outline"
                style="min-width: 115px;"
                @click="handleCancel">
                {{ $t('views.customer.form.cancel') }}
              </el-button>
              <el-button
                :disabled="!autoUsers.length || !suggestSelected"
                class="button-secondary"
                style="min-width: 170px;"
                @click="handleSave">
                {{ $t('views.admin.Add User') }}
              </el-button>
            </div>
          </el-form>
        </div>
      </PpCard>
    </PpModal>
    <RemoveUser
      v-if="appCode"
      :open.sync="removeUserModalActive"
      :user="selectedUser"
      :unlinked="true"
      :app-code="appCode" />
  </div>
</template>

<script>

import ClientManagementSubMenu from '@/views/admin/ClientManagementSubMenu'
import selectedClient from './mixins/selectedClient'
import RemoveUser from './components/RemoveUser'
import Pagination from '@/components/Pagination'

const DEFAULT_TABLE_LIMIT = 10

export default {
  name: 'ClientManagementUsers',
  components: {
    ClientManagementSubMenu,
    RemoveUser,
    Pagination
  },
  mixins: [selectedClient],
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.fetchData()
    })
  },
  data () {
    return {
      companyName: '',
      addUserModalActive: false,
      addUserForm: {
        usernameEmail: undefined
      },
      users: [],
      usersCount: 0,
      pagination: {
        page: 1,
        limit: DEFAULT_TABLE_LIMIT
      },
      autoUsers: [],
      isFetchingSuggestion: false,
      isFetching: false,
      roleOptions: [
        { label: this.$t('views.MyAccount.roleOptions.company-admin'), value: 'CLIENT_ADMIN' },
        { label: this.$t('views.MyAccount.roleOptions.agent'), value: 'CLIENT_AGENT_SBC' },
        { label: this.$t('views.MyAccount.roleOptions.itc'), value: 'CLIENT_AGENT_SB' },
        { label: this.$t('views.MyAccount.roleOptions.finance'), value: 'CLIENT_FINANCE' }
      ],
      suggestSelected: undefined,
      selectedUser: undefined,
      removeUserModalActive: false
    }
  },
  methods: {
    fetchData ({ limit = DEFAULT_TABLE_LIMIT, page = 1 } = {}) {
      this.isFetching = true
      this.pagination.limit = limit
      this.pagination.page = page

      const params = {
        appCode: this.$route.params.appCode,
        limit,
        page
      }
      this.$store.dispatch('admin/userList', params)
        .then(({ outlets }) => {
          this.users = outlets.items
          this.usersCount = outlets.totalItems
        })
        .catch(() => {})
        .finally(() => {
          this.isFetching = false
        })
    },
    handleSelected (row) {
      const uid = row.uid
      this.$router.push({
        name: 'admin/user-management/overview',
        params: { uid }
      })
    },
    handleSave () {
      this.$refs.addUserForm.validate((valid) => {
        if (valid) {
          const payload = {
            appCode: this.company.appCode,
            inactive: false,
            ...this.suggestSelected
          }
          this.$store.dispatch('admin/clientUser', payload).then(
            ({ outlets }) => {
              if (outlets.ok) {
                this.addUserForm.usernameEmail = undefined
                this.addUserModalActive = false
                this.fetchData()
              }
            }
          ).catch((err) => {
            if (err.error) {
              this.$message({
                message: err.error.message,
                type: 'error'
              })
              this.addUserForm.usernameEmail = undefined
              this.addUserModalActive = false
            }
          })
        }
      })
    },
    handleCancel () {
      this.$refs.addUserForm.resetFields()
      this.suggestSelected = undefined
      this.addUserModalActive = false
    },
    removeUser (user, companyName) {
      if (companyName) user.companyName = companyName
      this.selectedUser = user
      this.removeUserModalActive = true
    },
    handleFetchSuggestions (queryString, cb) {
      if (queryString !== '' && queryString.length > 2) {
        this.isFetchingSuggestion = true
        this.$store.dispatch('admin/userAutosuggest', { q: queryString.toLowerCase().trim() })
          .then(({ outlets }) => {
            this.autoUsers = outlets.items.filter((obj) => !this.users.map((user) => user.uid).includes(obj.uid))
            this.isFetchingSuggestion = false
            if (this.autoUsers.length > 0) {
              cb(this.autoUsers)
            } else {
              const list = [{ email: queryString, type: 'create' }]
              cb(list)
            }
          }).catch(() => {
            this.isFetchingSuggestion = false
          })
      }
    },
    handleUserSuggestSelect (item) {
      this.suggestSelected = item
    },
    handleStatusChange (e, row) {
      row.inactive = !e
      const payload = {
        appCode: this.company.appCode,
        ...row
      }
      this.$store.dispatch('admin/clientUser', payload).then(
        ({ outlets }) => {
          if (outlets.ok) {
            this.fetchData()
            this.$message({
              message: 'Status has been updated',
              type: 'success'
            })
          }
        }
      )
    },
    handleRoleChange (e, row) {
      row.roleCode = e
      const { roleName, ...rest } = row
      const payload = {
        appCode: this.company.appCode,
        ...rest
      }
      this.$store.dispatch('admin/clientUser', payload).then(
        ({ outlets }) => {
          if (outlets.ok) {
            this.$message({
              message: 'Role has been updated',
              type: 'success'
            })
          }
        }
      ).catch((err) => {
        this.fetchData()
        if (err.error) {
          this.$message({
            message: err.error.message,
            type: 'error'
          })
        } else {
          this.$message({
            message: err.message,
            type: 'error'
          })
        }
      })
    }
  },
  metaInfo () {
    const title = this.companyName ? `${this.companyName}'s Users` : ''
    return {
      title: title
    }
  }

}
</script>

<style scoped>

</style>
