<template>
  <div>
    <ClientManagementSubMenu :company="company">
      {{ companyName }}
    </ClientManagementSubMenu>
    <div class="section">
      <div class="container">
        <div class="columns">
          <div class="column">
            <h2 class="has-text-weight-bold is-size-4 has-text-secondary">
              {{ $t('views.admin.History') }}
            </h2>
          </div>
        </div>
        <el-table
          ref="dataTable"
          v-loading="isFetching"
          :data="tableData"
          stripe
          style="width: 100%"
          @row-click="showPayload">
          <el-table-column
            width="180"
            label="Timestamp (localtime)">
            <template slot-scope="scope">
              {{ scope.row.createdAt|date('MMM DD, YYYY HH:mm') }}
            </template>
          </el-table-column>
          <el-table-column label="Action">
            <template slot-scope="scope">
              <span class="has-text-uppercase">{{ scope.row.type }}</span>
            </template>
          </el-table-column>
          <el-table-column
            width="180"
            label="Payload">
            <template>
              <span class="has-text-secondary">Payload</span>
            </template>
          </el-table-column>
          <el-table-column
            width="120"
            property="by"
            label="By" />
        </el-table>
      </div>
    </div>
    <PpModal v-model="payloadModalActive">
      <PpCard class="box has-border is-flex">
        <div class="payload-modal-content">
          <pre>{{ selectPayload }}</pre>
          <div class="has-text-centered">
            <el-button
              class="button-secondary"
              style="min-width: 90px; margin-top: 30px;"
              @click="payloadModalActive=false">
              OK
            </el-button>
          </div>
        </div>
      </PpCard>
    </PpModal>
  </div>
</template>

<script>

import ClientManagementSubMenu from '@/views/admin/ClientManagementSubMenu'
import selectedClient from './mixins/selectedClient'

export default {
  name: 'ClientManagementHistory',
  components: {
    ClientManagementSubMenu
  },
  mixins: [selectedClient],
  data () {
    return {
      companyName: '',
      tableData: [],
      payloadModalActive: false,
      selectPayload: undefined
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      const params = {
        appCode: this.$route.params.appCode
      }
      this.isFetching = true
      this.$store.dispatch('admin/historyList', params).then(
        ({ outlets }) => {
          this.tableData = outlets.items.reverse()
          this.isFetching = false
        }
      ).catch(err => {
        this.$store.commit('SET_GLOBAL_ERROR_MODAL', {
          show: true,
          title: err.message || (err.error && err.error.message) || '',
          content: ''
        })
        this.isFetching = false
      })
    },
    showPayload (data) {
      this.selectPayload = data
      this.payloadModalActive = true
    }
  },
  metaInfo () {
    const title = this.companyName ? `${this.companyName}'s History` : ''
    return {
      title: title
    }
  }

}
</script>

<style scoped>

</style>
