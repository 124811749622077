<template>
  <div>
    <ClientManagementSubMenu :company="company">
      {{ companyName }}
    </ClientManagementSubMenu>
    <div
      v-if="!isFetchingClient"
      class="section">
      <div class="container">
        <div class="columns is-large-gap">
          <div class="column is-6">
            <h2 class="is-size-4 has-text-weight-bold has-text-secondary m-b-3x">
              {{ $t('views.admin.Client details') }}
            </h2>
            <div class="columns is-gapless columns-table m-b-0">
              <div class="column">
                <div class="th">
                  {{ $t('views.MyAccount.Company Info') }}
                </div>
              </div>
              <div class="column has-text-right">
                <div class="th">
                  <a
                    class="has-text-white"
                    @click.prevent="toggleEditModal('company')">
                    <IconBase
                      width="13"
                      height="13"
                      icon-name="edit">
                      <IconEdit />
                    </IconBase>
                    {{ $t('views.MyAccount.Edit') }}</a>
                </div>
              </div>
            </div>
            <div class="columns is-gapless columns-table m-b-0">
              <div class="column is-6">
                <div class="td">
                  <div class="has-text-weight-bold has-text-primary">
                    {{ $t('views.customer.form.company-name.label') }}
                  </div>
                  <div>{{ companyForm.companyName }}</div>
                </div>
              </div>
              <div class="column is-6">
                <div class="td">
                  <div class="has-text-weight-bold has-text-primary">
                    {{ $t('views.customer.form.phone-number.label') }}
                  </div>
                  <div>{{ companyForm.contactNo }}</div>
                </div>
              </div>
            </div>
            <div class="columns is-multiline is-gapless columns-table m-b-3x">
              <div class="column is-6">
                <div class="td">
                  <div class="has-text-weight-bold has-text-primary">
                    {{ $t('views.customer.form.company-address.label') }}
                  </div>
                  <div>{{ companyForm.address }}</div>
                </div>
              </div>
              <div class="column is-6">
                <div class="td">
                  <div class="has-text-weight-bold has-text-primary">
                    {{ $t('views.customer.form.city.label') }}
                  </div>
                  <div>{{ companyForm.city }}</div>
                </div>
              </div>
              <div class="column is-6">
                <div class="td">
                  <div class="has-text-weight-bold has-text-primary">
                    {{ $t('views.customer.form.country.label') }}
                  </div>
                  <div>{{ countryName }}</div>
                </div>
              </div>
              <div class="column is-6">
                <div class="td">
                  <div class="has-text-weight-bold has-text-primary">
                    {{ $t('views.customer.form.postal-code.label') }}
                  </div>
                  <div>{{ companyForm.postalCode || '-' }}</div>
                </div>
              </div>
              <div class="column is-6">
                <div class="td">
                  <div class="has-text-weight-bold has-text-primary">
                    {{ $t('views.customer.form.accounts-email.label') }}
                  </div>
                  <div class="has-text-break-word">
                    {{ companyForm.accountsEmail || '-' }}
                  </div>
                </div>
              </div>
              <div class="column is-6">
                <div class="td">
                  <div class="has-text-weight-bold has-text-primary">
                    {{ $t('views.customer.form.travel-group.label') }}
                  </div>
                  <div>{{ companyForm.travelGroup || '-' }}</div>
                </div>
              </div>
              <div class="column is-6">
                <div class="td">
                  <div class="has-text-weight-bold has-text-primary">
                    {{ $t('views.customer.form.sales-manager.label') }}
                  </div>
                  <div>{{ companyForm.salesManager || '-' }}</div>
                </div>
              </div>
              <div class="column is-6">
                <div class="td">
                  <div class="has-text-weight-bold has-text-primary">
                    {{ $t('views.customer.form.currency.label') }}
                  </div>
                  <div>{{ config.currencies.defaultDisplayCurrency }}</div>
                </div>
              </div>
            </div>

            <!-- upload logo start-->
            <div class="columns is-gapless m-b-0 section-header">
              <div class="column">
                <div>
                  {{ $t('company-logo-upload.label') }}
                </div>
              </div>
            </div>
            <div class="columns is-gapless columns-table m-b-3x">
              <div class="column is-12">
                <div class="td">
                  <div class="desc m-b-4x">
                    {{ $t('company-logo-upload.desc') }}
                  </div>
                  <div>
                    <LogoUpload
                      :value="logo"
                      @change="handleUploadCompanyLogo" />
                  </div>
                </div>
              </div>
            </div>
            <!-- upload logo end-->

            <div class="columns is-gapless columns-table m-b-0">
              <div class="column">
                <div class="th">
                  {{ $t('views.MyAccount.Email template') }}
                </div>
              </div>
              <div class="column has-text-right">
                <div class="th">
                  <a
                    class="has-text-white"
                    @click.prevent="toggleEditModal('email')">
                    <IconBase
                      width="13"
                      height="13"
                      icon-name="edit">
                      <IconEdit />
                    </IconBase>
                    {{ $t('views.MyAccount.Edit') }}</a>
                </div>
              </div>
            </div>
            <div class="columns is-multiline is-gapless columns-table m-b-0">
              <div class="column is-12">
                <div
                  class="td"
                  style="padding-bottom: 0;">
                  {{ $t('views.admin.email-template-displayed-to') }}
                </div>
              </div>
              <div class="column is-6">
                <div class="td">
                  <div class="has-text-weight-bold has-text-primary">
                    {{ $t('views.customer.form.company-name.label') }}
                  </div>
                  <div>{{ config.email.companyName }}</div>
                </div>
              </div>
              <div class="column is-6">
                <div class="td">
                  <div class="has-text-weight-bold has-text-primary">
                    {{ $t('views.customer.form.email.label') }}
                  </div>
                  <div class="has-text-break-word">
                    {{ config.email.emailSupport }}
                  </div>
                </div>
              </div>
            </div>
            <div class="columns is-multiline is-gapless columns-table m-b-0">
              <div class="column is-6">
                <div class="td">
                  <div class="has-text-weight-bold has-text-primary">
                    {{ $t('views.customer.form.phone-number.label') }}
                  </div>
                  <div>{{ config.email.contactNo }}</div>
                </div>
              </div>
              <div class="column is-6">
                <div class="td">
                  <div class="has-text-weight-bold has-text-primary">
                    WhatsApp number
                  </div>
                  <div>{{ config.email.whatsAppNo || '-' }}</div>
                </div>
              </div>
            </div>
            <div class="columns is-multiline is-gapless columns-table m-b-0">
              <div class="column is-6">
                <div class="td">
                  <div class="has-text-weight-bold has-text-primary">
                    {{ $t('views.customer.form.cancellation-policy-email.label') }}
                  </div>
                  <div>{{ showCancellationPolicyEmail }}</div>
                </div>
              </div>
              <div class="column is-6">
                <div class="td">
                  <div class="has-text-weight-bold has-text-primary">
                    {{ $t('views.customer.form.cancellation-policy-voucher.label') }}
                  </div>
                  <div>{{ showCancellationPolicyVoucher }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-6">
            <h2 class="is-size-4 has-text-weight-bold has-text-secondary m-b-3x">
              {{ $t('views.admin.Permission') }}
            </h2>
            <div class="columns is-mobile">
              <div class="column">
                <div class="has-text-weight-bold has-text-primary">
                  {{ $t('views.admin.Application status') }}
                </div>
              </div>
              <div class="column is-narrow">
                <el-switch v-model="status" />
              </div>
            </div>
            <div class="columns is-mobile">
              <div class="column">
                <div class="has-text-weight-bold has-text-primary">
                  {{ $t('views.admin.Availability search') }}
                </div>
                <div>{{ $t('views.admin.availability-search-desc') }}</div>
              </div>
              <div class="column is-narrow">
                <el-switch v-model="availabilitySearch" />
              </div>
            </div>
            <div class="columns is-mobile">
              <div class="column">
                <div class="has-text-weight-bold has-text-primary">
                  {{ $t('views.admin.Room reservation') }}
                </div>
                <div>{{ $t('views.admin.room-reservation-desc') }}</div>
              </div>
              <div class="column is-narrow">
                <el-switch v-model="reservation" />
              </div>
            </div>
            <div class="columns is-mobile">
              <div class="column">
                <div class="has-text-weight-bold has-text-primary">
                  {{ $t('views.admin.Pay at Hotel') }}
                </div>
                <div>{{ $t('views.admin.pay-at-hotel-desc') }}</div>
              </div>
              <div class="column is-narrow">
                <el-switch v-model="payAtHotel" />
              </div>
            </div>
            <hr>
            <div class="columns is-mobile">
              <div class="column">
                <div class="has-text-weight-bold has-text-primary">
                  Wallet (ZAR)
                </div>
                <div>Allow user to use wallet with ZAR currency.</div>
              </div>
              <div class="column is-narrow">
                <el-switch v-model="balanceZARIsActive" />
              </div>
            </div>
            <div class="columns is-mobile">
              <div class="column">
                <div class="has-text-weight-bold has-text-primary">
                  Wallet (USD)
                </div>
                <div>Allow user to use wallet with USD currency.</div>
              </div>
              <div class="column is-narrow">
                <el-switch v-model="balanceUSDIsActive" />
              </div>
            </div>
            <hr>
            <div class="columns is-mobile">
              <div class="column">
                <div class="has-text-weight-bold has-text-danger">
                  Delete client
                </div>
                <div>Once deleted, you cannot recover this client. Please be certain.</div>
              </div>
              <div class="column is-narrow">
                <el-button
                  type="danger"
                  @click="deleteClientModalActive=true">
                  Delete
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Edit modals -->
    <PpModal v-model="editModalActive">
      <PpCard class="box has-border is-flex">
        <div class="edit-profile-modal-content modal-columns-outer">
          <el-form
            ref="companyForm"
            :model="localCompanyForm"
            :rules="rules"
            class="columns is-multiline"
            label-position="top"
            status-icon>
            <div class="column is-12">
              <h3 class="is-size-4 has-text-secondary has-text-weight-bold">
                {{ $t('views.MyAccount.Edit company info') }}
              </h3>
            </div>
            <el-form-item
              :label="$t('views.customer.form.company-name.label')"
              class="column is-12"
              prop="companyName">
              <el-input
                v-model="localCompanyForm.companyName"
                type="text"
                autocomplete="companyName" />
            </el-form-item>
            <el-form-item
              :label="$t('views.customer.form.country.label')"
              class="column is-12"
              prop="country">
              <InputCountry
                v-model.trim="localCompanyForm.country"
                :placeholder="$t('views.customer.form.country.placeholder')"
                style="width: 100%" />
            </el-form-item>
            <el-form-item
              :label="$t('views.customer.form.accounts-email.label')"
              class="column is-12"
              prop="accountsEmail">
              <el-input
                v-model.trim="localCompanyForm.accountsEmail"
                type="email" />
            </el-form-item>
            <el-form-item
              :label="$t('views.customer.form.travel-group.label')"
              class="column is-12"
              prop="accountsEmail">
              <el-input
                v-model="localCompanyForm.travelGroup" />
            </el-form-item>
            <el-form-item
              :label="$t('views.customer.form.sales-manager.label')"
              class="column is-12"
              prop="accountsEmail">
              <el-input
                v-model="localCompanyForm.salesManager" />
            </el-form-item>
            <el-form-item
              :label="$t('views.customer.form.company-address.label')"
              class="column is-12"
              prop="address">
              <el-input
                v-model="localCompanyForm.address"
                type="text"
                autocomplete="companyAddress" />
            </el-form-item>
            <el-form-item
              :label="$t('views.customer.form.city.label')"
              class="column is-6"
              prop="city">
              <el-input
                v-model="localCompanyForm.city"
                type="text"
                autocomplete="city" />
            </el-form-item>
            <el-form-item
              :label="$t('views.customer.form.postal-code.label')"
              class="column is-6"
              prop="postalCode">
              <el-input
                v-model.trim="localCompanyForm.postalCode"
                type="text"
                autocomplete="postalCode" />
            </el-form-item>
            <el-form-item
              :label="$t('views.customer.form.country-code.label')"
              class="column is-4"
              prop="phoneCountry">
              <InputNationality
                v-model.trim="localCompanyForm.phoneCountry"
                :placeholder="$t('views.customer.form.country-code.placeholder')"
                display-type="ph" />
            </el-form-item>
            <el-form-item
              :label="$t('views.customer.form.phone-number.label')"
              class="column is-8"
              prop="phoneNumber">
              <el-input
                v-model.trim="localCompanyForm.phoneNumber"
                type="tel" />
            </el-form-item>
            <el-form-item
              :label="$t('views.customer.form.currency.label')"
              class="column is-6"
              prop="defaultDisplayCurrency">
              <InputCurrency
                v-model="localCompanyForm.defaultDisplayCurrency"
                style="width: 100%;" />
            </el-form-item>
            <div class="column is-12 has-text-right">
              <el-button
                class="button-secondary-outline"
                style="min-width: 115px;"
                @click="handleCancel">
                {{ $t('views.customer.form.cancel') }}
              </el-button>
              <el-button
                class="button-secondary"
                style="min-width: 125px;"
                @click="handleSave">
                {{ $t('views.customer.form.save') }}
              </el-button>
            </div>
          </el-form>
        </div>
      </PpCard>
    </PpModal>
    <PpModal v-model="editEmailTemplateActive">
      <PpCard class="box has-border is-flex">
        <div class="edit-profile-modal-content modal-columns-outer">
          <el-form
            ref="emailTemplateForm"
            :model="localEmailTemplateForm"
            :rules="emailTemplateRules"
            class="columns is-multiline"
            label-position="top"
            status-icon>
            <div class="column is-12">
              <h3 class="is-size-4 has-text-secondary has-text-weight-bold">
                {{ $t('views.MyAccount.Edit email template') }}
              </h3>
            </div>
            <el-form-item
              :label="$t('views.customer.form.company-name.label')"
              class="column is-12"
              prop="companyName">
              <el-input
                v-model="localEmailTemplateForm.companyName"
                type="text"
                autocomplete="companyName" />
            </el-form-item>
            <el-form-item
              :label="$t('views.customer.form.email.label')"
              class="column is-12"
              prop="emailSupport">
              <el-input
                v-model.trim="localEmailTemplateForm.emailSupport"
                :placeholder="$t('views.customer.form.email.placeholder')"
                type="email"
                autocomplete="email" />
            </el-form-item>
            <el-form-item
              :label="$t('views.customer.form.country-code.label')"
              class="column is-4"
              prop="phoneCountry">
              <InputNationality
                v-model.trim="localEmailTemplateForm.phoneCountry"
                :placeholder="$t('views.customer.form.country-code.placeholder')"
                display-type="ph" />
            </el-form-item>
            <el-form-item
              :label="$t('views.customer.form.phone-number.label')"
              class="column is-4"
              prop="phoneNumber">
              <el-input
                v-model.trim="localEmailTemplateForm.phoneNumber"
                type="tel"
                autocomplete="phoneNumber" />
            </el-form-item>
            <el-form-item
              label="WhatsApp number"
              class="column is-4"
              prop="whatsAppNumber">
              <el-input
                v-model.trim="localEmailTemplateForm.whatsAppNumber"
                type="tel" />
            </el-form-item>
            <el-form-item
              :label="$t('views.customer.form.cancellation-policy-email.label')"
              class="column is-6"
              prop="showCancellationPolicyEmail">
              <InputShow
                v-model.trim="localEmailTemplateForm.showCancellationPolicyEmail"
                :placeholder="$t('views.customer.form.cancellation-policy-email.placeholder')"
                style="width: 100%;" />
            </el-form-item>
            <el-form-item
              :label="$t('views.customer.form.cancellation-policy-voucher.label')"
              class="column is-6"
              prop="showCancellationPolicyVoucher">
              <InputShow
                v-model.trim="localEmailTemplateForm.showCancellationPolicyVoucher"
                :placeholder="$t('views.customer.form.cancellation-policy-voucher.placeholder')"
                style="width: 100%;" />
            </el-form-item>
            <div class="column is-12 has-text-right">
              <el-button
                class="button-secondary-outline"
                style="min-width: 115px;"
                @click="handleCancelEmailTemplate">
                {{ $t('views.customer.form.cancel') }}
              </el-button>
              <el-button
                class="button-secondary"
                style="min-width: 125px;"
                @click="handleSaveEmailTemplate">
                {{ $t('views.customer.form.save') }}
              </el-button>
            </div>
          </el-form>
        </div>
      </PpCard>
    </PpModal>
    <DeleteClient
      :open.sync="deleteClientModalActive"
      :app-code="appCode"
      :company-name="companyName" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ClientManagementSubMenu from '@/views/admin/ClientManagementSubMenu'
import IconEdit from '@/components/icons/IconEdit'
import InputNationality from '@/components/InputNationality'
import InputCountry from '@/components/InputCountry'
import { getCountryByAlpha2 } from '@/plugins/countriesHelper'
import { getPhoneNumber, phoneCountryCode } from '@/plugins/util'
import selectedClient from './mixins/selectedClient'
import LogoUpload from '@/components/LogoUpload'
import DeleteClient from './components/DeleteClient'
import InputCurrency from '@/components/InputCurrency'
import InputShow from '@/components/InputShow'

export default {
  name: 'ClientManagementOverview',
  components: {
    ClientManagementSubMenu,
    InputNationality,
    InputCountry,
    InputShow,
    IconEdit,
    InputCurrency,
    LogoUpload,
    DeleteClient
  },
  mixins: [selectedClient],
  data () {
    return {
      deleteClientModalActive: false,
      editModalActive: false,
      editEmailTemplateActive: false,
      localCompanyForm: {},
      localEmailTemplateForm: {},
      rules: {
        accountsEmail: [
          {
            type: 'email',
            message: this.$t('views.customer.form.accounts-email.invalid'),
            trigger: [
              'change', 'blur', 'input'
            ]
          }
        ],
        companyName: [
          {
            required: true,
            message: this.$t('views.customer.form.company-name.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ],
        country: [
          {
            required: true,
            message: this.$t('views.customer.form.country.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ],
        address: [
          {
            required: true,
            message: this.$t('views.customer.form.company-address.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ],
        city: [
          {
            required: true,
            message: this.$t('views.customer.form.city.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ],
        /* postalCode: [
          {
            required: true,
            message: this.$t('views.customer.form.postal-code.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ], */
        phoneCountry: [
          {
            required: true,
            message: this.$t('views.customer.form.country-code.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ],
        phoneNumber: [
          {
            type: 'string',
            message: this.$t('views.customer.form.phone-number.invalid'),
            trigger: [
              'change', 'blur'
            ],
            pattern: /^\+?[1-9]\d{1,14}$/ig
          }, {
            required: true,
            message: this.$t('views.customer.form.phone-number.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ]
      },
      emailTemplateRules: {
        companyName: [
          {
            required: true,
            message: this.$t('views.customer.form.company-name.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ],
        countryCode: [
          {
            required: true,
            message: this.$t('views.customer.form.country-code.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ],
        phoneNumber: [
          {
            type: 'string',
            message: this.$t('views.customer.form.phone-number.invalid'),
            trigger: [
              'change', 'blur'
            ],
            pattern: /^\+?[1-9]\d{1,14}$/ig
          }, {
            required: true,
            message: this.$t('views.customer.form.phone-number.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ],
        whatsAppNumber: [
          {
            type: 'string',
            message: 'Invalid  whatsApp number',
            trigger: [
              'change', 'blur'
            ],
            pattern: /^\+?[1-9]\d{7,10}$/
          }
        ],
        email: [
          {
            type: 'email',
            message: this.$t('views.customer.form.email.invalid'),
            trigger: [
              'change', 'blur', 'input'
            ]
          }, {
            required: true,
            message: this.$t('views.customer.form.email.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ]
      }
    }
  },
  computed: {
    ...mapState('admin', [
      'companyForm',
      'emailTemplateForm'
    ]),
    status: {
      get () {
        return !this.companyForm.inactive
      },
      set (val) {
        this.$store.commit('admin/SET_COMPANY_FORM', { inactive: !val })
        this.handlePermissionChange({ inactive: !val })
      }
    },
    availabilitySearch: {
      get () {
        return this.companyForm.availabilitySearch
      },
      set (val) {
        this.$store.commit('admin/SET_COMPANY_FORM', { availabilitySearch: val })
        this.handlePermissionChange({ availabilitySearch: val })
      }
    },
    reservation: {
      get () {
        return this.companyForm.reservation
      },
      set (val) {
        this.$store.commit('admin/SET_COMPANY_FORM', { reservation: val })
        this.handlePermissionChange({ reservation: val })
      }
    },
    payAtHotel: {
      get () {
        return this.config.payment.pay_at_hotel ? this.config.payment.pay_at_hotel.enabled : false
      },
      set (val) {
        const payload = {
          appCode: this.$route.params.appCode,
          enabled: val
        }
        this.config.payment.pay_at_hotel.enabled = val
        this.$store.dispatch('admin/updatePayAtHotelStatus', payload)
          .then(() => {
            this.$message({
              message: `Pay at hotel feature has been ${val ? 'activated' : 'disabled'}`,
              type: 'success'
            })
          })
      }
    },
    balanceZARIsActive: {
      get () {
        return this.companyForm.balanceZARIsActive
      },
      set (val) {
        this.$store.commit('admin/SET_COMPANY_FORM', { balanceZARIsActive: val })
        this.handleUpdateBalanceStatus({
          currency: 'ZAR',
          isActive: val
        })
      }
    },
    balanceUSDIsActive: {
      get () {
        return this.companyForm.balanceUSDIsActive
      },
      set (val) {
        this.$store.commit('admin/SET_COMPANY_FORM', { balanceUSDIsActive: val })
        this.handleUpdateBalanceStatus({
          currency: 'USD',
          isActive: val
        })
      }
    },
    countryName () {
      return getCountryByAlpha2(this.config.country, 'en-US').lb || ''
    },
    showCancellationPolicyEmail () {
      return this.cancellationPolicyLabel(this.config.email.showCancellationPolicyEmail)
    },
    showCancellationPolicyVoucher () {
      return this.cancellationPolicyLabel(this.config.email.showCancellationPolicyVoucher)
    }
  },
  methods: {
    handlePermissionChange (field) {
      this.$store.dispatch(
        'admin/updateClient',
        { appCode: this.$route.params.appCode, ...field }
      ).then(() => {
        this.$message({
          message: 'Permission has been updated',
          type: 'success'
        })
      }).catch((err) => {
        this.$message({
          message: err.message || (err.error && err.error.message) || '',
          type: 'error'
        })
      })
    },
    handleUpdateBalanceStatus (payload) {
      let balance = JSON.parse(JSON.stringify(this.company.balance))
      balance[payload.currency].isActive = payload.isActive
      this.$store.dispatch(
        'admin/updateClient',
        { appCode: this.$route.params.appCode, balance: balance }
      ).then(({ outlets }) => {
        this.company.balance = outlets.details.balance
        this.$message({
          message: `Wallet (${payload.currency}) has been ${payload.isActive ? 'activated' : 'disabled'}`,
          type: 'success'
        })
      }).catch((err) => {
        this.$message({
          message: err.message || (err.error && err.error.message) || '',
          type: 'error'
        })
      })
    },
    handleSave () {
      this.$refs.companyForm.validate((valid) => {
        if (valid) {
          this.localCompanyForm.contactNo = `(${this.localCompanyForm.phoneCountry.ph}) ${this.localCompanyForm.phoneNumber}`
          const payload = {
            appCode: this.$route.params.appCode,
            accountsEmail: this.localCompanyForm.accountsEmail,
            contactNo: this.localCompanyForm.contactNo,
            companyName: this.localCompanyForm.companyName,
            country: this.localCompanyForm.country,
            city: this.localCompanyForm.city,
            postalCode: this.localCompanyForm.postalCode,
            address: this.localCompanyForm.address,
            salesManager: this.localCompanyForm.salesManager,
            travelGroup: this.localCompanyForm.travelGroup,
            defaultDisplayCurrency: this.localCompanyForm.defaultDisplayCurrency
          }
          this.$store.dispatch('admin/updateCompanyInfo', payload)
            .then(() => {
              this.fetchClient()
              this.editModalActive = false
            })
        }
      })
    },
    handleCancel () {
      this.$refs.companyForm.resetFields()
      this.editModalActive = false
    },
    handleSaveEmailTemplate () {
      this.$refs.emailTemplateForm.validate((valid) => {
        if (valid) {
          this.localEmailTemplateForm.contactNo = `(${this.localEmailTemplateForm.phoneCountry.ph}) ${this.localEmailTemplateForm.phoneNumber}`
          this.localEmailTemplateForm.whatsAppNo = this.localEmailTemplateForm.whatsAppNumber ? `(${this.localEmailTemplateForm.phoneCountry.ph}) ${this.localEmailTemplateForm.whatsAppNumber}` : ''
          const payload = {
            appCode: this.$route.params.appCode,
            contactNo: this.localEmailTemplateForm.contactNo,
            companyName: this.localEmailTemplateForm.companyName,
            emailSupport: this.localEmailTemplateForm.emailSupport,
            whatsAppNo: this.localEmailTemplateForm.whatsAppNo,
            showCancellationPolicyEmail: this.localEmailTemplateForm.showCancellationPolicyEmail,
            showCancellationPolicyVoucher: this.localEmailTemplateForm.showCancellationPolicyVoucher
          }
          this.$store.dispatch('admin/updateEmailTemplate', payload)
            .then(() => {
              this.fetchClient()
              this.editEmailTemplateActive = false
            })
        } else {
          // todo
          return false
        }
      })
    },
    handleCancelEmailTemplate () {
      this.$refs.emailTemplateForm.resetFields()
      this.editEmailTemplateActive = false
    },
    toggleEditModal (form) {
      if (form === 'company') {
        this.localCompanyForm = {
          ...this.localCompanyForm,
          ...this.companyForm,
          phoneNumber: getPhoneNumber(this.companyForm.contactNo),
          phoneCountry: phoneCountryCode(this.companyForm.contactNo),
          defaultDisplayCurrency: this.config.currencies.defaultDisplayCurrency
        }
        this.editModalActive = true
      } else if (form === 'email') {
        this.localEmailTemplateForm = {
          ...this.localEmailTemplateForm,
          ...this.emailTemplateForm,
          phoneNumber: getPhoneNumber(this.emailTemplateForm.contactNo),
          phoneCountry: phoneCountryCode(this.emailTemplateForm.contactNo),
          whatsAppNumber: getPhoneNumber(this.emailTemplateForm.whatsAppNo),
          showCancellationPolicyEmail: this.emailTemplateForm.showCancellationPolicyEmail,
          showCancellationPolicyVoucher: this.emailTemplateForm.showCancellationPolicyVoucher
        }
        this.editEmailTemplateActive = true
      }
    },
    handleUploadCompanyLogo (logoObj) {
      const payload = {
        appCode: this.$route.params.appCode,
        image: logoObj
      }
      this.$store.dispatch('admin/updateCompanyLogo', payload)
        .then(() => {
          this.fetchClient()
        })
        .catch(err => {
          this.$store.commit('SET_GLOBAL_MODAL_ERROR', {
            show: true,
            content: err.message || (err.error && err.error.message) || ''
          })
        })
    },
    cancellationPolicyLabel (show) {
      if (typeof show === 'undefined') show = true
      return show ? this.$t('showOptions.yes') : this.$t('showOptions.no')
    }
  },
  metaInfo () {
    const title = this.companyName ? `${this.companyName}'s Overview` : ''
    return {
      title: title
    }
  }
}
</script>

<style scoped>

</style>
