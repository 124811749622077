<template>
  <el-select
    v-model="localValue"
    filterable
    :placeholder="placeholder"
    value-key="a3"
    @change="$emit('input', localValue.a2)">
    <el-option
      v-for="it in countries"
      :key="it.a3"
      :value="it"
      :label="displayType==='a3'?$t(`countries.${it.a3}`):$t(`countries.${it.a3}`) + ' (' + it[displayType] + ')'" />
  </el-select>
</template>

<script>
import countries from '../plugins/countries'
import { getCountryByAlpha2 } from '@/plugins/countriesHelper'

export default {
  name: 'InputCountry',
  props: {
    value: {
      type: String
    },
    placeholder: {
      type: String,
      default: 'Nationality'
    },
    displayType: {
      type: String,
      default: 'a3'
    }
  },
  data () {
    return {
      countries,
      localValue: {}
    }
  },
  created () {
    this.localValue = getCountryByAlpha2(this.value)
  }
}
</script>

<style scoped>

</style>
