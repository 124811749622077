<template>
  <div>
    <UserManagementSubMenu
      v-if="!isFetchingUser"
      :user-role="user.roleCode">
      {{ user.displayName }}
    </UserManagementSubMenu>
    <div class="section">
      <div class="container">
        <div class="columns">
          <div class="column">
            <h2 class="has-text-weight-bold is-size-4 has-text-secondary">
              {{ $t('views.admin.History') }}
            </h2>
          </div>
        </div>
        <el-table
          ref="dataTable"
          v-loading="isFetching"
          :data="tableData"
          stripe
          style="width: 100%"
          @row-click="showPayload">
          <el-table-column
            width="180"
            label="Timestamp (localtime)">
            <template slot-scope="scope">
              {{ scope.row.createdAt|date('MMM DD, YYYY HH:mm') }}
            </template>
          </el-table-column>
          <el-table-column label="Action">
            <template slot-scope="scope">
              <span>{{ getType(scope.row.type) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            width="180"
            label="Payload">
            <template>
              <span class="has-text-secondary">Payload</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Status"
            width="120">
            <template slot-scope="scope">
              <span class="has-text-uppercase">{{ scope.row.event && scope.row.event.status }}</span>
            </template>
          </el-table-column>
          <el-table-column
            width="120"
            property="by"
            label="By" />
        </el-table>
      </div>
    </div>
    <PpModal v-model="payloadModalActive">
      <PpCard class="box has-border is-flex">
        <div class="payload-modal-content">
          <pre>{{ selectPayload }}</pre>
          <div class="has-text-centered">
            <el-button
              class="button-secondary"
              style="min-width: 90px; margin-top: 30px;"
              @click="payloadModalActive=false">
              OK
            </el-button>
          </div>
        </div>
      </PpCard>
    </PpModal>
  </div>
</template>

<script>

import UserManagementSubMenu from '@/views/admin/UserManagementSubMenu'
import selectedUser from './mixins/selectedUser'

export default {
  name: 'UserManagementHistory',
  components: {
    UserManagementSubMenu
  },
  mixins: [selectedUser],
  data () {
    return {
      companyName: '',
      payloadModalActive: false,
      selectPayload: undefined
    }
  },
  computed: {
    tableData () {
      return this.user ? this.histories : []
    }
  },
  methods: {
    showPayload (data) {
      this.selectPayload = data
      this.payloadModalActive = true
    },
    getType (historyType) {
      let result = historyType
      switch (historyType) {
      case 'CREATED':
        result = this.$t('views.admin.history-page.action-type.CREATED')
        break
      case 'EDITED':
        result = this.$t('views.admin.history-page.action-type.EDITED')
        break
      case 'INVITATION_ISSUED':
        result = this.$t('views.admin.history-page.action-type.INVITATION_ISSUED')
        break
      case 'INVITATION_REISSUED':
        result = this.$t('views.admin.history-page.action-type.INVITATION_REISSUED')
        break
      case 'INVITATION_ACCEPTED':
        result = this.$t('views.admin.history-page.action-type.INVITATION_ACCEPTED')
        break
      case 'FORGOT_PASSWORD':
        result = this.$t('views.admin.history-page.action-type.FORGOT_PASSWORD')
        break
      case 'RESET_PASSWORD_ISSUED':
        result = this.$t('views.admin.history-page.action-type.RESET_PASSWORD_ISSUED')
        break
      case 'RESET_PASSWORD_CONFIRMED':
        result = this.$t('views.admin.history-page.action-type.RESET_PASSWORD_CONFIRMED')
        break
      case 'CHANGE_PASSWORD':
        result = this.$t('views.admin.history-page.action-type.CHANGE_PASSWORD')
        break
      }
      return result
    }
  },
  metaInfo () {
    const title = this.user ? `${this.user.displayName}'s History` : ''
    return {
      title: title
    }
  }

}
</script>

<style scoped>

</style>
