<template>
  <AdminSubMenu
    :menu="menu"
    :sub-menu="subMenu">
    <slot />
  </AdminSubMenu>
</template>

<script>
import AdminSubMenu from '@/views/admin/AdminSubMenu'

export default {
  name: 'ClientManagementSubMenu',
  components: { AdminSubMenu },
  props: {
    company: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      menu: {
        name: 'admin/client-management'
      }
    }
  },
  computed: {
    subMenu () {
      let subMenu = [
        {
          name: 'admin/client-management/overview',
          title: this.$t('views.admin.Overview')
        },
        {
          name: 'admin/client-management/users',
          title: this.$t('views.admin.Users')
        },
        {
          name: 'admin/client-management/pricing',
          title: this.$t('views.admin.Pricing'),
          role: ['SUPER_ADMIN', 'FINANCE_ADMIN']
        }
      ]
      if (this.$enableWallet() && this.company.balance && this.company.balance.USD.isActive) {
        subMenu.push({
          name: 'admin/client-management/wallet',
          params: { currency: 'USD' },
          title: `Wallet (USD)`,
          role: ['SUPER_ADMIN', 'FINANCE_ADMIN']
        })
      }
      if (this.$enableWallet() && this.company.balance && this.company.balance.ZAR.isActive) {
        subMenu.push({
          name: 'admin/client-management/wallet',
          params: { currency: 'ZAR' },
          title: `Wallet (ZAR)`,
          role: ['SUPER_ADMIN', 'FINANCE_ADMIN']
        })
      }
      subMenu.push({
        name: 'admin/client-management/history',
        title: this.$t('views.admin.History')
      })
      return subMenu
    }
  }
}
</script>

<style scoped>

</style>
