<template>
  <div class="section p-l-0 p-r-0">
    <div class="container is-fluid">
      <h1 class="is-size-3 has-text-primary has-text-weight-bold m-b-4x">
        {{ $t('views.admin.Client Management') }}
      </h1>
      <el-form
        ref="localForm"
        :model="localForm"
        class="columns is-mini-gap is-multiline"
        label-position="top"
        status-icon
        @submit.native.prevent="">
        <el-form-item
          class="column is-narrow"
          prop="retypePassword">
          <el-input
            v-model="localForm.search"
            type="text"
            autocomplete="search"
            placeholder="Search client name, client admin"
            style="min-width: 320px;"
            @input="delaySearch" />
        </el-form-item>
        <el-form-item
          class="column is-narrow hide-border-radius-right"
          prop="dateRangeType">
          <el-select
            v-model="localForm.dateRangeType"
            :placeholder="$t('views.MyBookings.form.dateRange.placeholder')"
            @change="handleDateRangeTypeChange">
            <el-option
              v-for="item in dateRangeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item
          class="column is-narrow hide-border-radius-left"
          prop="dateRange">
          <DatePicker
            v-model="localForm.dateRange"
            :hovering-tooltip="false"
            :min-nights="0"
            hide-nights
            no-min-date
            show-year
            display-clear-button
            @input="handleDateRangeChange" />
        </el-form-item>
        <div class="column is-narrow">
          <el-button
            v-if="hasSearchParams"
            class="button-secondary-outline m-r-3x"
            style="min-width: 100px;"
            @click="clear">
            CLEAR
          </el-button>
        </div>
        <div class="column has-text-right">
          <el-button
            type="primary"
            @click="handleExport">
            {{ $t('views.MyAccount.Export to Excel') }}
          </el-button>
          <el-button
            class="button-secondary"
            @click="openConfigureColumnModal">
            {{ $t('views.admin.Configure columns') }}
          </el-button>
        </div>
      </el-form>
      <el-table
        v-if="items.length"
        ref="singleTable"
        :data="items"
        stripe
        style="width: 100%"
        @row-click="handleSelected"
        @sort-change="handleSortChanged">
        <el-table-column
          v-for="(col,idx) in activeColumns"
          :key="col.value+idx"
          :label="$t(`views.admin.${col.label}`) || col.label"
          :property="col.prop"
          :sortable="'custom'"
          :width="col.width"
          min-width="150">
          <template slot-scope="scope">
            <span v-if="!col.html">{{ cellFormatter(scope.row, col) }}</span>
            <span
              v-else
              v-html="cellFormatter(scope.row, col)" />
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        :total-items="totalItems"
        :page="localForm.page"
        :page-size="localForm.limit"
        :pager-count="5"
        top-element="#app"
        @change="handlePageChange" />
    </div>
    <PpModal
      v-model="columnConfigurationModalActive"
      class="column-configuration-modal">
      <PpCard class="box has-border is-flex">
        <div class="column-configuration-modal-content">
          <h3 class="is-size-4 has-text-secondary has-text-weight-bold m-b-2x">
            {{ $t('views.admin.Column configurations') }}
          </h3>
          <h4 class="is-size-5 has-text-primary has-text-weight-bold m-b-2x">
            {{ $t('views.admin.Select columns you want to see') }}
          </h4>
          <ul class="col-select-list">
            <li
              v-for="(c, idx) in configColumns"
              :key="idx">
              <div class="col-item">
                <el-checkbox v-model="c.active">
                  <b class="is-block">{{ $t(`views.admin.${c.label}`) || c.label }}</b>
                  <span class="is-block">{{ $t(`views.admin.${c.desc}`) || c.desc }}</span>
                </el-checkbox>
              </div>
            </li>
          </ul>
          <hr style="margin: 15px 0!important;">
          <h4 class="is-size-5 has-text-primary has-text-weight-bold m-b-2x">
            {{ $t('views.admin.Drag to rearrange the order') }}
          </h4>
          <p>* {{ $t('views.admin.columns-configure-desc') }}</p>
          <draggable
            v-model="configColumns"
            class="column-sort-list m-b-6x">
            <div
              v-for="c in configColumns"
              :key="c.value">
              <div
                v-if="c.active"
                class="column-sort-item">
                <div class="active-item">
                  <span class="column-name">{{ $t(`views.admin.${c.label}`) || c.label }}</span>
                  <span
                    class="column-remove-btn"
                    @click="handleRemoveColumn(c)"><i class="ap-icon-close" /> </span>
                </div>
              </div>
            </div>
          </draggable>
          <div class="has-text-right">
            <el-button
              class="button-secondary-outline"
              style="min-width: 115px;"
              @click="columnConfigurationModalActive = false">
              {{ $t('views.admin.Cancel') }}
            </el-button>
            <el-button
              class="button-secondary"
              style="min-width: 150px;"
              @click="handleSave">
              {{ $t('views.admin.Save') }}
            </el-button>
          </div>
        </div>
      </PpCard>
    </PpModal>
  </div>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'
import draggable from 'vuedraggable'
import debounce from 'lodash/debounce'

import DatePicker from '@/components/DatePicker/index'
import Pagination from '@/components/Pagination'
import { download } from '@/plugins/util'
import clientColumns from '@/store/modules/clientColumns'
import { getCountryByAlpha2 } from '@/plugins/countriesHelper'

export default {
  name: 'ClientManagement',
  components: {
    Pagination,
    DatePicker,
    draggable
  },
  filters: {
    country (v) {
      return getCountryByAlpha2(v).lb || v
    }
  },
  data () {
    return {
      localForm: {
        search: '',
        limit: 10,
        page: 1,
        order: 'DESC',
        sortBy: 'createdAt',
        dateRange: {
          from: null,
          to: null
        },
        dateRangeType: null
      },
      dateRangeOptions: [
        {
          value: 'createdAt',
          label: 'Created at'
        },
        {
          value: 'updatedAt',
          label: 'Updated at'
        }
      ],
      items: [],
      totalItems: 0,
      configColumns: [],
      columnConfigurationModalActive: false
    }
  },
  computed: {
    ...mapState('admin', {
      cols: state => state.clientCols
    }),
    hasSearchParams () {
      const localForm = this.localForm
      return localForm.search !== '' || localForm.dateRange.from !== null || localForm.dateRange.to !== null
    },
    activeColumns () {
      const activeColumns = []
      const inactiveColumns = []
      this.cols.map((col) => {
        const c = clientColumns.findByKey(col)
        if (c) {
          activeColumns.push(c)
        } else {
          inactiveColumns.push(col)
        }
      })
      const keys = this.cols.filter((c) => inactiveColumns.indexOf(c) < 0)
      this.$store.dispatch('admin/setClientCols', keys)
      return activeColumns.filter(_ => _)
    }
  },
  created () {
    this.$store.dispatch('admin/loadClientCols')
    this.configColumns = clientColumns.list
    this.fetchData({ ...this.localForm })
  },
  methods: {
    fetchData (params = {}) {
      const {
        q,
        page,
        limit,
        order,
        sortBy,
        filters = {}
      } = params

      this.$store.dispatch('admin/clientList', {
        q,
        page,
        limit,
        order,
        sortBy,
        filters
      }).then(
        ({ outlets }) => {
          this.items = outlets.items
          this.totalItems = outlets.totalItems
        }
      )
    },
    handleSearch (e = null) {
      if (e) e.preventDefault()
      this.localForm.page = 1
      this.fetchData({
        q: this.localForm.search,
        page: this.localForm.page,
        limit: this.localForm.limit,
        filters: this.buildFilters()
      })
    },
    handleSelected (row) {
      const appCode = row.appCode
      this.$router.push({ name: 'admin/client-management/overview', params: { appCode } })
    },
    handlePageChange ({ page, limit }) {
      this.localForm.page = page
      this.localForm.limit = limit
      this.fetchData({
        q: this.localForm.search,
        page: this.localForm.page,
        limit: this.localForm.limit,
        filters: this.buildFilters()
      })
    },
    delaySearch: debounce(function () {
      this.handleSearch()
    }, 500),
    clear () {
      this.localForm = {
        search: '',
        page: 1,
        limit: 10,
        dateRange: {
          from: null,
          to: null
        },
        dateRangeType: null
      }
      this.handleSearch()
    },
    handleSortChanged ({ order, prop }) {
      const sortOrder = {
        ascending: 'ASC',
        descending: 'DESC'
      }
      this.localForm.order = sortOrder[order]
      this.localForm.sortBy = prop
      this.fetchData({
        q: this.localForm.search,
        page: this.localForm.page,
        limit: this.localForm.limit,
        order: this.localForm.order,
        sortBy: this.localForm.sortBy,
        filters: this.buildFilters()
      })
    },
    handleExport () {
      let store = this.$store
      store.dispatch('admin/exportClientXLS', {
        q: this.localForm.search,
        limit: this.localForm.limit,
        page: this.localForm.page,
        order: this.localForm.order,
        sortBy: this.localForm.sortBy,
        locale: this.localForm.locale,
        filters: this.buildFilters(),
        cols: this.cols.join(',')
      }).then(({
        data,
        headers
      }) => {
        this.exportModalActive = false
        download(data, headers)
      }).catch((err) => {
        if (err.meta) store.commit('admin/SET_META', err.meta)
        store.commit('SET_GLOBAL_MODAL', {
          show: true,
          content: err.message || (err.error && err.error.message) || ''
        })
      })
    },
    handleDateRangeChange (values) {
      if (this.localForm.dateRangeType === null) {
        this.localForm.dateRangeType = 'createdAt'
      }

      this.localForm.dateRange.from = values.checkInDate
      this.localForm.dateRange.to = values.checkOutDate

      this.fetchData({
        q: this.localForm.search,
        page: this.localForm.page,
        limit: this.localForm.limit,
        filters: this.buildFilters()
      })
    },
    handleDateRangeTypeChange () {
      if (this.localForm.dateRange.from !== null && this.localForm.dateRange.to !== null) {
        this.fetchData({
          q: this.localForm.search,
          page: this.localForm.page,
          limit: this.localForm.limit,
          filters: this.buildFilters()
        })
      }
    },
    buildFilters () {
      const filters = {}

      if (this.localForm.dateRangeType !== null && this.localForm.dateRange.from !== null && this.localForm.dateRange.to !== null) {
        filters.date = {
          field: this.localForm.dateRangeType,
          value: {
            from: moment(this.localForm.dateRange.from).startOf('day').toISOString(),
            to: moment(this.localForm.dateRange.to).endOf('day').toISOString()
          }
        }
      }

      return filters
    },
    cellFormatter (row, val) {
      if (!val.formatter) return row[val.value]
      return val.formatter(row, this.$root.$options.filters, this.$options.filters)
    },
    handleRemoveColumn (c) {
      c.active = false
    },
    openConfigureColumnModal () {
      this.columnConfigurationModalActive = true
      this.configColumns.map((col) => {
        if (this.cols.includes(col.key)) col.active = true
      })
    },
    handleSave () {
      this.columnConfigurationModalActive = false
      const keys = []
      this.configColumns.map((col) => {
        if (col.active) {
          keys.push(col.key)
        }
      })
      this.$store.dispatch('admin/setClientCols', keys)
    }
  },
  metaInfo () {
    return {
      title: 'Client Management'
    }
  }
}
</script>

<style lang="scss">
@import "../../styles/bulma-variables";

.column-configuration-modal .pp-modal--content {
    max-width: 1250px;
  }

  .col-select-list {
    @include tablet {
      display: flex;
      flex-wrap: wrap;
    }

    .el-checkbox {
      white-space: initial;
    }

    .el-checkbox__label {
      padding-left: 20px;
    }

    .el-checkbox__input {
      position: absolute;
      top: 5px;
      left: 0px;
    }

    li {
      margin-bottom: 15px;
      box-sizing: border-box;
      @include tablet {
        flex: 0 0 20%;
        max-width: 20%;
        .col-item {
          padding-right: 5px;
        }
      }
    }
  }

  .column-sort-list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .column-sort-item {
      display: block;
      background-color: $primary;
      color: #fff;
      cursor: pointer;
      text-align: center;
      height: 100%;
      white-space: nowrap;
      padding: 10px 15px;
      margin: 2px;
      position: relative;

      .column-remove-btn {
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        border-radius: 50%;
        border: 1px solid $danger-color;
        color: $danger-color;
        background-color: $white;
        display: none;
        position: absolute;
        right: -4px;
        top: -4px;
        z-index: 10;
      }

      &:hover {
        .column-remove-btn {
          display: block;
        }
      }

    }
  }
</style>
