<template>
  <div>
    <PpModal v-model="open">
      <PpCard class="box has-border is-flex">
        <div class="edit-profile-modal-content modal-columns-outer">
          <el-form
            ref="negativeBalanceForm"
            :model="negativeBalanceForm"
            :rules="rules"
            class="columns is-multiline"
            label-position="top">
            <div class="column is-12">
              <h3 class="is-size-4 has-text-secondary has-text-weight-bold">
                Allow minimum balance
              </h3>
            </div>
            <div class="column is-12">
              <p>How much negative balance allowed?</p>
            </div>
            <el-form-item
              class="column is-narrow"
              prop="amount">
              <div class="el-input-number-wrapper">
                <money-input v-model="negativeBalanceForm.amount" />
                <span
                  class="hack-input-number-suffix has-text-weight-bold"
                  style="right: 30px">{{ currency }}</span>
                <!-- <el-input-number
                  v-model="negativeBalanceForm.amount"
                  :min="0"
                  :controls="false"
                  placeholder="Amount"/>
                <span class="hack-input-number-suffix">USD</span> -->
              </div>
            </el-form-item>

            <div class="column is-12 has-text-right">
              <el-button
                class="button-secondary-outline"
                style="min-width: 115px;"
                @click="handleCancel">
                Cancel
              </el-button>
              <el-button
                class="button-secondary"
                style="min-width: 170px;"
                @click="handleSave">
                Save
              </el-button>
            </div>
          </el-form>
        </div>
      </PpCard>
    </PpModal>
    <PpModal v-model="confirmModalActive">
      <PpCard class="box has-border is-flex">
        <div class="edit-profile-modal-content modal-columns-outer">
          <el-form
            ref="confirmForm"
            :model="confirmForm"
            class="columns is-multiline"
            label-position="top">
            <div class="column is-12">
              <h3 class="is-size-4 has-text-secondary has-text-weight-bold">
                Confirm negative balance
              </h3>
            </div>
            <div class="column is-12">
              <p class="m-b">
                You are about to change the negative balance to -{{ negativeBalanceForm.amount|currency({currency:currency}) }}
              </p>
              <p class="m-b">
                Are you sure you want to proceed?
              </p>
            </div>

            <div class="column is-12 has-text-right">
              <el-button
                class="button-secondary-outline"
                style="min-width: 115px;"
                @click="handleConfirmCancel">
                Cancel
              </el-button>
              <el-button
                class="button-secondary"
                style="min-width: 170px;"
                @click="handleConfirm">
                Proceed
              </el-button>
            </div>
          </el-form>
        </div>
      </PpCard>
    </PpModal>
  </div>
</template>

<script>
import MoneyInput from '@/components/MoneyInput'

export default {
  name: 'NegativeBalanceModal',
  components: {
    MoneyInput
  },
  props: {
    open: {
      type: Boolean,
      default: false
    },
    companyName: {
      type: String,
      default: ''
    },
    amount: {
      type: Number,
      default: 0
    },
    currency: {
      type: String,
      default: 'USD'
    }
  },
  data () {
    return {
      confirmModalActive: false,
      negativeBalanceForm: {
        amount: this.amount
      },
      rules: {
        amount: [
          {
            required: true,
            message: 'Amount is required.',
            trigger: [
              'blur'
            ]
          },
          {
            type: 'number',
            min: 0,
            message: 'Only enter positive numbers.',
            trigger: ['change']
          }
        ]
      },
      confirmForm: {}
    }
  },
  watch: {
    amount (nV) {
      this.negativeBalanceForm.amount = nV
    },
    remarks (nV) {
      this.negativeBalanceForm.remarks = nV
    }
  },
  methods: {
    handleSave () {
      this.$refs.negativeBalanceForm.validate((valid) => {
        if (valid) {
          this.$emit('update:open', false)
          this.confirmModalActive = true
          return true
        } else {
          return false
        }
      })
    },
    handleCancel () {
      this.$refs.negativeBalanceForm.resetFields()
      this.$emit('update:open', false)
    },
    handleConfirm () {
      this.$emit('confirm', {
        ...this.negativeBalanceForm
      })
      this.confirmModalActive = false
    },
    handleConfirmCancel () {
      this.negativeBalanceForm.amount = undefined
      this.confirmModalActive = false
    }
  }
}
</script>

<style scoped>

</style>
